import "./App.css";
import Navbar from "./navbar/Navbar";
import Splash from "./splash/Splash";
import Brand from "./brand-carousel/Brand";
import AboutUs from "./aboutUs/AboutUs";
import Team from "./team/Team";
import VcStudio from "./vcStudio/vcStudio";
import Research from "./research/research";
import Footer from "./footer/Footer";
import altusArtwork1 from "./assets/altus_ventures@img_2.png";
import React from "react";

function App() {
  return (
    <div className="App">
      <div className="App-view">
        <Navbar></Navbar>
        <Splash></Splash>
        <Brand></Brand>
        <AboutUs></AboutUs>
        <img className="feature-img" src={altusArtwork1} alt="" />
        <Research></Research>
        <VcStudio></VcStudio>
        <Team></Team>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
