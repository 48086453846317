import React from "react";
import "./vcStudio.css";
import altusbar from "../assets/altus_ventures@img_1.png";

const VcStudio = () => (
  <div className="vc-section-container section-container" id="vc-studio">
    <div className="vc-section-row">
      <div className="vc-studio-bullet">
        <h2>VC Studio</h2>
        <p>
          The Venture Capital Studio is a launchpad for ambitious tech projects
          across a wide range of industries.{" "}
        </p>
        <br></br>
        <div></div>
        <p>
          Our passionate team of experts continuously pushes the boundaries of
          technological advancement, and with our forward-thinking approach, we
          drive companies rapidly from infancy to maturity.{" "}
        </p>
        <br></br>
        <div></div>
        <p>
          VC Studio takes pride in nurturing groundbreaking concepts into
          successful enterprises, setting the pace in the ever-evolving digital
          landscape. We leverage our vast industry connections and technical
          experience to bring companies to life.{" "}
        </p>
        <br></br>
      </div>
      <img className="feature-img" src={altusbar} alt="" />
    </div>
  </div>
);

export default VcStudio;
