import React, { useState } from "react";
import logo from "../assets/altus-ventures-logo_white.png";
import "./footer.css";
import Modal from "./Modal";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="footer">
      <img className="altus-footer-logo" src={logo} alt="" />{" "}
      <small>Unit 12, 10-11 Archer St, London W1D 7AZ </small>
      <small className="footer-link" onClick={() => setIsOpen(true)}>
        Privacy Policy
      </small>
      <small> © Altus Ventures Limited </small>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}></Modal>
    </div>
  );
};

export default Footer;
