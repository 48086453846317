import React from "react";
import "./team.css";
import oern from "../assets/oern-temp.png";
import alex from "../assets/alex-temp.png";

import tamas from "../assets/tamas_img.png";
import gabriel from "../assets/gabriel_img.png";
import george from "../assets/george_img.png";
import jonah from "../assets/jonah_img.png";

const Team = () => (
  <>
    <div className="section-container" id="who-we-are">
      <h2>Founders</h2>
      <div className="section-copy">
        <div className="team-bullet-container">
          <div className="team-bullet">
            <img
              className="team-img"
              src={alex}
              alt="portrait of Alex Jackson"
            />
            <h4>Alex Jackson</h4>
            <p>
              Alex has 10 years’ experience in creating and developing a range
              of businesses across both finance and technology. Previously, he
              held roles in trading and quantitative modelling in major
              investment banks, and was a lecturer in Mathematics at Oxford
              University.{" "}
            </p>
          </div>
          <div className="team-bullet">
            <img className="team-img" src={oern} alt="portrait of Örn Greif" />
            <h4>Örn Greif</h4>
            <p>
              Örn is a founding partner of numerous technology ventures and
              disruptive enterprises in the UK and the EU, and has three decades
              of experience in investment banking. He is a polyglot, and has
              studied Linguistics, Politics, Banking & Finance, and Real Estate
              Investment at UCLA, IEP de Paris, the Sorbonne and Cambridge
              University.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="section-container">
      <h2>Innovators</h2>
      <div className="section-copy">
        <div className="team-bullet-container">
          <div className="team-bullet">
            <img
              className="team-img"
              src={tamas}
              alt="portrait of Tamás Stenczel"
            />
            <h4>Tamás Stenczel</h4>
            <p>
              Tamás is the head of our R&D Team, and leads our research efforts.
              He has previous experience in scientific computing, and studied
              Natural Sciences at the University of Cambridge, where he is
              conducting academic research towards a PhD.
            </p>
          </div>
          <div className="team-bullet">
            <img
              className="team-img"
              src={jonah}
              alt="portrait of Jonah Vinsome"
            />
            <h4>Jonah Vinsome</h4>
            <p>
              Jonah is our expert in applied mathematics. He has previously
              worked in industrial computing and studied Mathematics at the
              University of Bristol and King’s College London.
            </p>
          </div>
        </div>
      </div>
      <div className="section-copy">
        <div className="team-bullet-container">
          <div className="team-bullet">
            <img className="team-img" src={gabriel} alt="portrait of Gabriel" />
            <h4>Gabriel Shannon</h4>
            <p>
              Gabriel is our principal product designer and engineer. With
              expertise ranging from software development to CGI, he has lead
              innovation in commercial applications of cryptocurrency and
              e-commerce.
            </p>
          </div>
          <div className="team-bullet">
            <img className="team-img" src={george} alt="" />
            <h4>George McCarthy</h4>
            <p>
              George is one of our R&D engineers and is leading a Venture
              Capital Studio project in AI. He studied Physics at the University
              of Bath and continues to publish in Astrophysics.
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Team;
