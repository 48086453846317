import React from "react";
import "./research.css";

const Research = () => (
  <div className="section-container" id="research">
    <div className="section-heading">
      <h2>Pioneering Research</h2>
      <p>
        We enlist a dedicated research team with expertise in investment
        banking, scientific research and industrial engineering.
      </p>
    </div>

    <div className="section-copy">
      <div className="research-bullet-container">
        <div className="research-bullet">
          <div />
          <h3>Artificial Intelligence</h3>
          <p>
            Bridging insights from the forefronts of machine learning and
            natural science, our AI team is developing the next generation of
            intelligent software.{" "}
          </p>
        </div>
        <div className="research-bullet">
          <div />
          <h3>Cryptography</h3>
          <p>
            Our cryptography team is pushing the global transition into a secure
            and decentralised web. We ensure mathematically-proven security for
            any kind of digital asset.{" "}
          </p>
        </div>
        <div className="research-bullet">
          <div />
          <h3>Data</h3>
          <p>
            Our developments in machine learning and graph theory are bringing
            businesses unparalleled insights and revealing deeply-hidden
            connections in their data.{" "}
          </p>
        </div>
        <div className="research-bullet">
          <div />
          <h3>Fintech</h3>
          <p>
            Our industry experts are leading the charge into the economies of
            tomorrow. Reducing friction in transactions, and ensuring that
            investors’ assets are moved securely to wherever best suits their
            needs.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Research;
