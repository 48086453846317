import * as ReactDom from "react-dom";
import "./footer.css";

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-60%, -50%)",
  color: "#D8DADE;",
  zIndex: 1000,
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
};

export default function Modal({ open, children, onClose }) {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES}>
        <p className="btn-close" onClick={onClose}>
          <b>X</b>
        </p>
        <small>
          This privacy statement was last updated January, 2024 and applies to
          citizens and legal permanent residents of the United Kingdom.
        </small>
        <br></br>
        <br></br>

        <small>
          Altus Ventures Group Limited is committed to complying with the
          relevant privacy laws that relate to protecting your personal data and
          you as a data subject. These include but are not limited to The
          General Data Protection Act (GDPR) and the Privacy and Electronic
          Communications Regulations, and any other data protection guidance or
          laws that may come into force in the UK.
        </small>
      </div>
    </>,
    document.getElementById("portal"),
  );
}
