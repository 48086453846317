import React from "react";
import {
  arcus,
  kloudwerk,
  gems,
  socius,
  praeco,
  openml,
  altusDigital,
  dura,
} from "./imports";
import "./brand.css";

const Brand = () => (
  <div className="section-container">
    <div className="brand-carousel-container">
      <div>
        <a href="https://arcusforadvisers.com/">
          {" "}
          <img className="carousel-img" src={arcus} alt="" />{" "}
        </a>
      </div>
      <div>
        <a href="https://sociustec.com/">
          {" "}
          <img className="carousel-img" src={socius} alt="logo" />{" "}
        </a>
      </div>
      <div>
        <a href="https://www.duracapital.co.uk/">
          {" "}
          <img className="carousel-img" src={dura} alt="logo" />{" "}
        </a>
      </div>

      <div>
        <a href="https://kloudwerk.com/">
          {" "}
          <img className="carousel-img" src={kloudwerk} alt="" />{" "}
        </a>
      </div>
      <div>
        <img className="carousel-img" src={gems} alt="logo" />
      </div>
      <div>
        <a href="https://altusdigital.net">
          {" "}
          <img className="carousel-img" src={altusDigital} alt="" />{" "}
        </a>
      </div>

      <div>
        <a href="https://praeco.digital/">
          {" "}
          <img className="carousel-img" src={praeco} alt="logo" />{" "}
        </a>
      </div>

      <div>
        <a href="https://openml.digital/">
          {" "}
          <img className="carousel-img" src={openml} alt="logo" />{" "}
        </a>
      </div>
    </div>
  </div>
);

export default Brand;
