import React from "react";
import "./aboutUs.css";

const AboutUs = () => (
  <div className="section-container" id="aboutUs">
    <div className="section-heading">
      <h2>About Altus Ventures</h2>
      <p>
        We solve technical business challenges through ground-breaking research
        and innovation. Our engineers design cutting-edge solutions that can
        operate at scale.{" "}
      </p>
    </div>
    <div className="section-copy">
      <div className="about-bullet-container">
        <div className="about-bullet">
          <div />
          <h3>Incubation</h3>
          <p>
            We provide a fertile environment intended for growing ideas into
            businesses. We offer both financing and expertise to cultivate new
            business strategies, construct tech solutions and build the
            necessary foundations for a robust commercial infrastructure.{" "}
          </p>
        </div>
        <div className="about-bullet">
          <div />
          <h3>Engineering</h3>
          <p>
            We engineer sophisticated, tailor-made solutions that not only
            respond to present challenges but are ready to adapt to future
            technological advancements. Scale and longevity are the first
            principles of all of our design processes.{" "}
          </p>
        </div>
        <div className="about-bullet">
          <div />
          <h3>Scale</h3>
          <p>
            We leverage market insights, forge strategic partnerships and
            implement robust growth strategies. We aim to scale business
            thoughtfully and efficiently from the ground up, fostering
            sustainable growth.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default AboutUs;
