import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../assets/altus_ventures@icon.png";
import "./navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navbar">
      <img className="altus-nav-logo" src={logo} alt="" />
      <p className="nav-item">
        <a href="#aboutUs">About Us</a>
      </p>
      <p className="nav-item">
        <a href="#research">Research</a>
      </p>

      <p className="nav-item">
        <a href="#vc-studio">VC Studio</a>
      </p>
      <p className="nav-item">
        <a href="#who-we-are">Team</a>
      </p>
      <div className="mobile-altus-navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="mobile-navbar-menu-container scale-up-center">
            <p>
              <a href="#aboutUs">About Us</a>
            </p>
            <p>
              <a href="#research">Research</a>
            </p>

            <p>
              <a href="#vc-studio">VC Studio</a>
            </p>
            <p>
              <a href="#who-we-are">Who We Are</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
